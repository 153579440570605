import * as bootstrap from "bootstrap";

// Delete tenant modal handlers.
$(() => {
    const deleteTenantModal = document.getElementById("deleteTenantModal");
    if (deleteTenantModal === null) return;

    function handleModalInputChange(e) {
        const currentValue = e.target.value;
        const expectedConfirmation = e.target.dataset.confirmation;
        const btn = document.getElementById("tenantDeleteConfirmBtn");

        btn.disabled = currentValue !== expectedConfirmation;
    }
    function handleModalShow(_e) {
        const input = document.getElementById("deleteConfirmation");
        input.addEventListener("input", handleModalInputChange);
    };
    function handleModalHide(_e) {
        const input = document.getElementById("deleteConfirmation");
        const btn = document.getElementById("tenantDeleteConfirmBtn");
        input.removeEventListener("input", handleModalInputChange);
        input.value = ""; // reset the value so that on the consequent modal open it's empty
        btn.disabled = true;
    };

    deleteTenantModal.addEventListener('show.bs.modal', handleModalShow);
    deleteTenantModal.addEventListener('hide.bs.modal', handleModalHide);
});

// Tenant OAuth2 application show page handlers.
$(() => {
    const secretValueSection = document.getElementById("client_secret");
    if (secretValueSection === null) {
        return;
    }

    const masked = secretValueSection.querySelector(".masked");
    const visibilityBtn = secretValueSection.querySelector("button.toggle-visibility");
    const revealIcon = visibilityBtn.querySelector(".eye-hidden");
    const hideIcon = visibilityBtn.querySelector(".eye-shown");
    const copyBtn = secretValueSection.querySelector("button.copy");

    function handleToggleVisibility(_e) {
        const isHidden = masked.dataset.hidden == "true";

        if (isHidden) {
            masked.textContent = masked.dataset.value;
            masked.dataset.hidden = "false";
            hideIcon.classList.remove("visually-hidden");
            revealIcon.classList.add("visually-hidden");
        } else {
            masked.textContent = "*".repeat(masked.dataset.value.length);
            masked.dataset.hidden = "true";
            revealIcon.classList.remove("visually-hidden");
            hideIcon.classList.add("visually-hidden");
        }
    }

    const tooltip = new bootstrap.Tooltip(copyBtn, {
        placement: "bottom",
        title: "Copied!",
        trigger: "manual",
    });

    function handleCopy(_e) {
        const value = masked.dataset.value;

        window.navigator.clipboard.writeText(value);

        tooltip.show();
        setTimeout(() => { tooltip.hide(); }, 3000);
    }

    visibilityBtn.addEventListener("click", handleToggleVisibility);
    copyBtn.addEventListener("click", handleCopy);
});

// User role change pop-up handler.
$(() => {
    const modal = document.getElementById("updateUserRoleModal");
    if (modal === null) return;

    const form = document.getElementById("updateUserRoleForm");
    if (form === null) return;

    function handleRoleSelectChange(e) {
        const val = e.target.value;

        const hints = form.querySelectorAll("div.role-hint");
        for (let i = 0; i < hints.length; ++i) {
            const hint = hints[i];

            if (hint.id === `role-hint-${val}`) {
                hint.classList.remove("visually-hidden");
            } else {
                hint.classList.add("visually-hidden");
            }
        }
    }

    const roleSelect = form.querySelector("select");
    roleSelect.addEventListener("change", handleRoleSelectChange);

    function handleModalSetup(e) {
        const btn = e.currentTarget;
        form.action = btn.dataset.submitUri;

        const csrfTokenInput = form.querySelector("input[name='authenticity_token']");
        csrfTokenInput.value = btn.dataset.csrfToken;
    }

    function handleModalCleanup(_e) {
        // unset the submit uri to ensuler that the form won't be submitted
        //  for an invalid user.
        form.action = "#";
        roleSelect.value = "";
        form.querySelector("input[name='authenticity_token']").value = "";

        const hints = form.querySelectorAll("div.role-hint");
        for (let i = 0; i < hints.length; ++i) {
            hints[i].classList.add("visually-hidden");
        }
    }

    modal.addEventListener("hide.bs.modal", handleModalCleanup);

    const changeRoleBtns = document.querySelectorAll("button.change-role");

    for (let i = 0; i < changeRoleBtns.length; ++i) {
        const btn = changeRoleBtns[i];
        btn.addEventListener("click", handleModalSetup);
    }
});

// User role change pop-up handler.
$(() => {
    const modal = document.getElementById("removeUserModal");
    if (modal === null) return;

    const form = document.getElementById("removeUserForm");
    if (form === null) return;

    function handleModalSetup(e) {
        const btn = e.currentTarget;
        form.action = btn.dataset.submitUri;

        const csrfTokenInput = form.querySelector("input[name='authenticity_token']");
        csrfTokenInput.value = btn.dataset.csrfToken;
    }

    function handleModalCleanup(_e) {
        // unset the submit uri to ensuler that the form won't be submitted
        //  for an invalid user.
        form.action = "#";
        form.querySelector("input[name='authenticity_token']").value = "";
    }

    modal.addEventListener("hide.bs.modal", handleModalCleanup);

    const removeUserBtns = document.querySelectorAll("button.remove-user");

    for (let i = 0; i < removeUserBtns.length; ++i) {
        const btn = removeUserBtns[i];
        btn.addEventListener("click", handleModalSetup);
    }
});
